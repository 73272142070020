// src/text.ts
import { Node } from "@tiptap/core";
var Text = Node.create({
  name: "text",
  group: "inline"
});

// src/index.ts
var src_default = Text;
export {
  Text,
  src_default as default
};
